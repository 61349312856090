import { depositNavigationData } from '../navigationData';

export default [{
    title: 'Deposit',
    subtitle: `1. Click on the Cashier button`,
    img: 'click-cashier',
    navigationreferenceid: `#${depositNavigationData[0].id}`,
    imgOverlay: true,
    blueIndexSubtitle: true,
  }, {
    subtitle: '2. Click Deposit VPP/ETH',
    img: 'click-deposit',
    navigationreferenceid: `#${depositNavigationData[1].id}`,
    imgOverlay: true,
    blueIndexSubtitle: true,
  }, {
    subtitle: '3. Click the Copy button to copy the Virtue Poker contract address.',
    img: 'click-deposit',
    navigationreferenceid: `#${depositNavigationData[2].id}`,
    imgOverlay: true,
    blueIndexSubtitle: true,
  }, {
    subtitle: '4. Click the Refresh button to view your new balance. Note that the transaction will take a few minutes to complete.',
    img: 'refresh-balance',
    navigationreferenceid: `#${depositNavigationData[3].id}`,
    imgOverlay: true,
    blueIndexSubtitle: true,
}];
