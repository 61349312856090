import React from 'react';
import { depositNavigationData } from './navigationData';
import depositContentData from './Contents/depositContent';
import Header from './Header';
import MSTable from '../HowToPlay/MSTable';
import checkId  from './checkId';
import config from '../../config';

import * as s from './s.module.css';

interface State {
    id: String,
}

interface Props {
    location: any,
}

class DepositPage extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
        }
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        window.history.replaceState('', '', '/gettingstarted/deposit');
        let id = '';
        if (params.get('step')) {
            id = `#${params.get('step')}`;
        }
        if (checkId(depositNavigationData, id)) {
            this.setState({ id: id });
        }
    }

    render() {
        return (
            <section className={s.sectionContainerGettingStarted}>
                <div className={s.sectionContentGettingStarted}>
                    <Header location={this.props.location}/>
                    <MSTable navigationData={depositNavigationData} contentData={depositContentData} id={this.state.id} />
                </div>
            </section>
        )
    }
}

export default DepositPage;
