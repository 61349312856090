export default (navigationData: any, id: any) => {
    const list = [];
    navigationData.map((obj: { id: String, subtitles?: Array<any>}) => {
        if (obj.subtitles && obj.subtitles.length) {
            obj.subtitles.map( sub => {
                list.push(`#${sub.id}`)
            })
        }
        list.push(`#${obj.id}`);
    })
    return list.includes(id);
}
