import React from 'react';
import cx from 'classnames';
import * as s from './s.module.css';
import Button from '../Button';

interface Props {
    active: any,
    onClick?: any,
    children?: any,
}

class RoundButton extends React.Component<Props> {
    render() {
        const { active, onClick, children } = this.props;
        return (
            <div className={cx(s.myButtonContainer)}>
                <Button onClick={onClick} className={cx(s.myButton, { [s.active]: active })}>{children}</Button>
            </div>
        );
    }
}

export default RoundButton;
