import React, { Fragment } from 'react';
import Title from '../../Title';
import RoundButton from '../../RoundButton';
import { navigate } from 'gatsby';

import * as s from './s.module.css';

interface Props {
    location: any,
}

class Header extends React.Component<Props> {
    render() {
        return (
            <Fragment>
                <Title>Getting Started</Title>
                <div className={s.tabBtnsContainerGettingStarted}>
                    <RoundButton active={this.props.location.pathname.includes('/gettingstarted/create-account')} onClick={() => navigate('/gettingstarted/create-account')}>Create Account</RoundButton>
                    <RoundButton active={this.props.location.pathname.includes('/gettingstarted/deposit')} onClick={() => navigate('/gettingstarted/deposit')}>Deposit</RoundButton>
                    <RoundButton active={this.props.location.pathname.includes('/gettingstarted/withdrawl')} onClick={() => navigate('/gettingstarted/withdrawl')}>Withdrawal</RoundButton>
                </div>
            </Fragment>
        )
    }
}

export default Header;
