import React from "react"

import Layout from "../../layout/default"
import Seo from "../../components/seo"
import Deposit from '../../components/GettingStarted/depositPage';

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Deposit" />
    <Deposit location={location}/>
  </Layout>
)

export default IndexPage
