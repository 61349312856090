export const createAccountNavigationData = [{
    title: "1. Download the App",
    id: 'step0'
}, {
    title: `2. Launch Virtue Poker and click Sign up`,
    id: 'step1'
}, {
    title: "3. Create your account",
    id: 'step2',
    subtitles: [
        {
            name: "Create and confirm your password",
            id: 'step3'
        }, {
            name: "Enter your personal information",
            id: 'step4',
        }, {
            name: "Enter your display name",
            id: 'step5'
        }, {
            name: "Enter your email verification code",
            id: 'step6'
        }, {
            name: "First step completed",
            id: 'step7'
        }, {
            name: "Link your wallet",
            id: 'step8'
        }, {
            name: "Paste your Ethereum address",
            id: 'step9'
        }, {
            name: "Create your wallet",
            id: 'step10'
        }, {
            name: "Secure your wallet",
            id: 'step11'
        }, {
            name: "Your registration is complete",
            id: 'step12'
        }
    ]
}];

export const depositNavigationData = [{
    title: `1. Click on the Cashier button`,
    id: 'step13',
}, {
    title: '2. Click Deposit VPP/ETH',
    id: 'step14',
}, {
    title: "3. Click the Copy button to copy…",
    id: 'step15',
}, {
    title: '4. Click the Refresh button to view…',
    id: 'step16',
}];

export const withdrawalNavigationData = [{
    title: "1.Click on the Cashier Button",
    id: 'step17',
}, {
    title: "2.Click on Withdraw VPP/ETH",
    id: 'step18',
}];
